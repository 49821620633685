import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import { css } from "@emotion/react"
import TwitterIcon from "../assets/twitter.svg"
import LinkIcon from "../assets/link.svg"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import SEO from "../components/seo"

const colors = [
  "#ffa39e",
  "#ffbb96",
  "#ffd591",
  "#ffe58f",
  "#fffb8f",
  "#eaff8f",
  "#b7eb8f",
  "#87e8de",
  "#91d5ff",
  "#adc6ff",
  "#d3adf7",
  "#ffadd2",
]

const getColors = word => {
  let N = word.length
  let res = 0
  for (let i = 0; i < N; i++) {
    res = (5 * res + word.charCodeAt(i)) % 12
  }
  // .charCodeAt(0)
  return colors[res]
}

export default function Directory({ data }) {
  const [tags, setTags] = useState()
  const [checks, setChecks] = useState()

  const {
    allAirtable: { edges },
  } = data

  // useEffect(() => {
  //   let s = new Set()
  //   let tagMap = {}
  //   if (edges !== undefined) {
  //     for (let i = 0; i < edges.length; i++) {
  //       let temp = edges[i].node.data.Tags
  //       for (let j = 0; j < temp.length; j++) {
  //         s.add(temp[j])
  //       }
  //     }
  //     let temp = Array.from(s)
  //     for (let i = 0; i < temp.length; i++) {
  //       tagMap[temp[i]] = false
  //     }
  //   }
  //   setTags(s)
  //   setChecks(tagMap)
  // }, [edges])

  const handleChange = (event, tag) => {
    setChecks({ ...checks, [tag]: event.target.checked })
    console.log(checks)
  }

  const verify = tags => {
    for (let i = 0; i < tags.length; i++) {
      if (checks[tags[i]] === false) {
        return false
      }
    }
  }

  return (
    <>
      <div
      // css={css`
      //   display: flex;
      //   flex-direction: column;
      //   @media only screen and (min-width: 1200px) {
      //     flex-direction: row;
      //   }
      //   color: black;
      // `}
      >
        {/* <div>
          {tags === undefined ? (
            <div>test</div>
          ) : (
            Array.from(tags).map((tag, i) => (
              <div>
                <input
                  type="checkbox"
                  id={tag}
                  name={tag}
                  onChange={e => handleChange(e, tag)}
                />{" "}
                <label for={tag}>{tag} </label>
              </div>
            ))
          )}
        </div> */}

        <div>
          <div
            css={css`
              margin: 0 30px;
              margin-bottom: 50px;
              font-family: "Montserrat", sans-serif;
            `}
          >
            <h1
              css={css`
                text-align: center;
                margin-top: 30px;
                margin-bottom: 0;
              `}
            >
              Miami Tech Week Directory
            </h1>
            <h2
              css={css`
                margin-top: 10px;
                font-size: 20px;
                font-weight: 300;
                text-align: center;
              `}
            >
              <a
                css={css`
                  color: #0178bd;
                `}
                target="_blank"
                href="https://airtable.com/shrGvjxTNw5v0kzd4"
              >
                Add to directory
              </a>
            </h2>
            <h3
              css={css`
                margin-top: 10px;
                font-size: 18px;
                font-weight: 300;
                text-align: center;
              `}
            >
              <a
                css={css`
                  color: #0178bd;
                `}
                target="_blank"
                href="https://docs.google.com/spreadsheets/d/12dHUTTQLQuy7HAOHbuv2LvCR9UKgbviWN-QpyYA1wyM/edit#gid=0"
              >
                Get the latest events here
              </a>
            </h3>

            <SEO
              title="Miami Tech Week Directory"
              description="Twitter directory of people at Miami Tech Week."
              twitter
            />
            <div
              css={css`
                @media only screen and (max-width: 600px) {
                  display: flex;
                  flex-direction: column;
                }

                @media only screen and (max-width: 800px) {
                  grid-template-columns: 1fr 1fr;
                }

                max-width: 1000px;
                margin: 0 auto;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: auto;
              `}
            >
              {edges.map(({ node: { data } }) => (
                <div
                  css={css`
                    /* @media only screen and (max-width: 600px) {
                      width: 90%;
                    }
                    @media only screen and (min-width: 600px) {
                      width: 90%;
                    }
                    @media only screen and (min-width: 768px) {
                      width: 45%;
                    }
                    @media only screen and (min-width: 1200px) {
                      width: 30%;
                    } */

                    overflow-wrap: anywhere;
                    padding: 10px;
                    margin-bottom: 50px;
                    /* height: 600px; */
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    h2 {
                      font-size: 20px;
                      font-weight: 500;
                      margin-bottom: 0;
                    }

                    span {
                    }
                    a {
                      color: #0178bd;
                    }
                  `}
                >
                  <div>
                    <img
                      src={data["Twitter_Profile_Image"]}
                      css={css`
                        width: 80%;

                        border-radius: 5px;
                      `}
                    ></img>
                    <h2>{data["Name"]}</h2>
                    {data["Twitter"] && (
                      <div
                        css={css`
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          margin-top: 5px;
                        `}
                      >
                        <TwitterIcon
                          css={css`
                            width: 20px;

                            margin-right: 5px;
                          `}
                        />
                        <a href={data["Twitter"]} target="_blank">{`@${data[
                          "Twitter"
                        ]
                          .split("twitter.com/")[1]
                          .replace("/", "")}`}</a>
                      </div>
                    )}
                    {data["Company_URL"] && (
                      <div
                        css={css`
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          margin-top: 5px;
                        `}
                      >
                        <LinkIcon
                          css={css`
                            width: 20px;

                            margin-right: 5px;
                          `}
                        />
                        <a href={data["Company_URL"]} target="_blank">
                          {data["Company_URL"]}
                        </a>
                      </div>
                    )}
                    <div
                      css={css`
                        height: 130px;
                      `}
                    >
                      <p>{data["Twitter_Bio"]}</p>
                    </div>
                  </div>
                  {data["Tags"] && (
                    <div>
                      {data["Tags"].map(tag => (
                        <span
                          style={{
                            background: getColors(tag),
                            padding: "2px 6px",
                            "border-radius": "20px",
                            "margin-right": "5px",
                            "font-size": "13px",
                            marginTop: "10px",
                          }}
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <footer
              css={css`
                margin-top: 30px;
                text-align: center;
                width: 100%;
              `}
            >
              <h4
                css={css`
                  font-weight: 300;
                  font-size: 16px;
                  text-align: center;

                  a {
                    color: #0178bd;
                    text-decoration: none;
                  }
                `}
              >
                Built by{" "}
                <a target="_blank" href="https://twitter.com/dhruvik_parikh7">
                  @dhruvik_parikh7
                </a>
                ,{" "}
                <a target="_blank" href="https://twitter.com/vvhuang_">
                  @vvhuang
                </a>
                ,{" "}
                <a target="_blank" href="https://twitter.com/dkb868">
                  @dkb868
                </a>
              </h4>
              A <Link to="/">Blog Surf</Link> Project
            </footer>
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query MiamiQuery {
    allAirtable(
      filter: { table: { eq: "miami" } }
      sort: { order: ASC, fields: data___Name }
    ) {
      totalCount
      edges {
        node {
          data {
            Name
            Twitter
            Twitter_Profile_Image
            Twitter_Bio
            Tags
            Company_URL
          }
        }
      }
    }
  }
`
